import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { compose, graphql } from "react-apollo";
import i18n from '../../../views/Pages/Login/i18n';
import MaskInput from 'react-maskinput'
import { Button, Form, FormGroup, FormText, Input, Alert } from 'reactstrap';
import ReactDOM from 'react-dom';
import { createReadingMutation } from "../../../queries/Queries";
import ExpiringAlert from "../../../components/ExpiringAlert";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import ModalDialog from "../../../components/ModalDialog";
import gql from "graphql-tag";
// import EventLogger from "../../../components/EventLogger";
import {getOptions, pad,  filterMeterPointsByActiveRegisters, getUniqueMeterPoints} from '../../../utils/Helpers';
import axios from "axios";

const READING_SUBMIT_CHANNEL_360 = "MY360";

const CreateReading = ({estateList, bpEstateId, toggle, ...props}) => {
    const [date, setDate] = useState(moment());
    const [focus, setFocus] = useState(null);
    const [submittingReading, setSubmittingReading] = useState(false);
    
    // State for all input options
    const [inputOptions, setInputOptions] = useState({
        estateList: null,
        meterPointsList: null,
        meterList: null,
        registerList: null
    });

    // State for onChange
    const [estateID, setEstateID] = useState(''); // For input value and submit
    const [meterPointID, setMeterPointID] = useState(''); // For getting registers and meters
    const [selectedMeterPoint, setSelectedMeterPoint] = useState(null);
    const [meterID, setMeterID] = useState('');
    const [selectedMeter, setSelectedMeter] = useState(null);
    const [readingsMapByRegisterId, setReadingsMapByRegisterId] = useState({}); // For submitting form
    const [lastReading, setLastReading] = useState('');

    /**
     * Trigger to set a list of estates and to set the estates id and estates name if there is only one.
     */
    useEffect(() => {
        const uniqueMeterPoints = getUniqueMeterPoints(estateList);
        const transformEstate = uniqueMeterPoints.reduce((acc, estate) => {
            acc.push({...estate, meterPoints: filterMeterPointsByActiveRegisters(estate.meterPoints)})

            return acc;
        }, [])

        setInputOptions(prevState => ({...prevState, estateList: transformEstate})); // Used for address and setEstateID
        if (transformEstate && transformEstate.length === 1) { // Set estate if only one
            setEstateID(transformEstate[0].id);
        }
    }, [])

    /**
     * Trigger if we have only one estate or if selected from Input to set meter point list and set meter point and its ID if only one
     */
    useEffect(() => {
        if (inputOptions.estateList) {
            const meterPointsList = inputOptions.estateList.filter(estate => estate.id === estateID); // Get meter points for the selected estate
            setInputOptions({
                ...inputOptions,
                meterPointsList: meterPointsList[0].meterPoints
            })
            if (meterPointsList[0].meterPoints.length === 1) { // Set a meter point and its ID if there is only one
                setMeterPointID(meterPointsList[0].meterPoints[0].id);
                setSelectedMeterPoint(meterPointsList[0].meterPoints[0]);
            }
        }
    }, [estateID])
    
    /**
     * The trigger if is just one or we select meter point from the input to set a list of meters and a list of registers.
     * Set a selected meter point (the meter point ID is set in the input) if not from the above useEffect and the selected meter and its ID.
     */
    useEffect(() => {
        if (inputOptions.meterPointsList) {
            const meterPoint = inputOptions.meterPointsList.filter(mp => mp.id === meterPointID); // Get a selected meter point
            const registerList = meterPoint.flatMap(register => register.registers); // Get selected meter point registers 
            const meterList = registerList.map(meter => meter.meter); // Get selected meter point/register/meters
            const metersToDisplay = Array.from(new Set(meterList.map(JSON.stringify))).map(JSON.parse); // Get a unique identifier and meter ID

            setInputOptions({
                ...inputOptions,
                meterList: meterList,
                registerList: registerList
            })
            if (!selectedMeterPoint || selectedMeterPoint.id !== meterPoint[0].id) setSelectedMeterPoint(meterPoint[0]); // Set meter point if not already selected
            if (metersToDisplay.length === 1) { // Set the selected meter and meter ID if there is only one
                setMeterID(meterList[0].id);
                setSelectedMeter(meterList[0]);
            }
        }
    }, [meterPointID])
    
    /**
     * Trigger if only one or selected by meter input to set selected meter (meter ID set in input)
     */
   useEffect(() => {
       if (inputOptions.meterList) {
            setReadingsMapByRegisterId({}); // reset state for new readings
            setLastReading(''); // Clear state when meter is changed to hide the message: "Reading cannot be submitted more than once a day." if shown
            const meter = inputOptions.meterList.filter(meter => meter.id === meterID); // Get a selected meter
            if (!selectedMeter || selectedMeter.id !== meter[0].id) setSelectedMeter(meter[0]); // Set the selected meter if is not already selected
        }
    }, [meterID])

    const isOutsideRange = day => day.isAfter(moment());

    const renderMaskInput = () => {
        return (selectedMeter && !selectedMeter.isHH) ? inputOptions.registerList
            .filter(meter => meter.meter.id === meterID)
            .map(register => {
                const lastReading = register.readings ? register.readings
                    .map(x => ({
                            ...x,
                            date: moment(x.date, "YYYY-MM-DDTHH:mm:ssZ"),
                            type: x.type === 'C' ? 'Customer' : (x.type === 'A' ? 'Actual' : (x.type === 'E' ? 'Estimated' : (x.type === 'AE' ? 'Estimated' : 'Actual')))
                        }
                    ))
                    .sort((a, b) => b.date.isBefore(a.date) ? -1 : 1)[0]
                : null;
                if (!readingsMapByRegisterId['reading' + register.id]) setReadingsMapByRegisterId(prevState => ({...prevState, ['reading' + register.id]: "0"}));

                return (
                    <FormGroup>
                        <div className="last-reading">
                            <label>
                                Last Read {lastReading && (<span><br /> Type: {lastReading.type}<br /></span>)}
                                {lastReading && "From: " + lastReading.date.format("DD/MM/YYYY")}
                            </label>
                            <p>{lastReading ? pad(lastReading.value, register.digits) : "N/A"}</p>
                        </div>
                        <label>{register.rate}:&nbsp;</label><br />
                        <MaskInput
                            value={readingsMapByRegisterId['reading' + register.id]}
                            onChange={e => {
                                const {value} = e.target;
                                setReadingsMapByRegisterId(prevState => ({...prevState, ['reading' + register.id]: value}));
                                setLastReading(lastReading);
                            }}
                            alwaysShowMask
                            maskChar="0"
                            mask={"0".repeat(register.digits)}
                            placeholder=""
                            className={'reading-input'}
                            style={{ width: register.digits * 24 }}
                        />
                    </FormGroup>
                )
            }) : null
    }
    
    const validateRegisters = (registers) => {
        const passedRegisters = []
        registers.forEach(register => passedRegisters.push({name: "reading" + register.id , productId: register.rateId}));
        return passedRegisters;
    }
    
    const handleSubmit = () => {
        setSubmittingReading(true);

        if (selectedMeterPoint && selectedMeterPoint.hasCorrector) {
            if (toggle) toggle();
            ReactDOM.render(
                <ModalDialog 
                    title={i18n.t('createReading.meterPointWithCorrectorTitle')}
                    text={i18n.t('createReading.meterPointWithCorrector')}
                    opened={true}
                    closeLabel={"Close"} 
                />, 
                document.getElementById('alert').appendChild(document.createElement("div")));
            return;
        }

        const refetchQuery = sessionStorage.listOfReadsQuery && JSON.parse(sessionStorage.listOfReadsQuery);
            if (refetchQuery) {
                refetchQuery.query = refetchQuery.query && gql`${refetchQuery.query}`;
            }

        if (!isOutsideRange(date)) {
            const passedRegisters = validateRegisters(selectedMeterPoint.registers.filter(register => register.meter.id === meterID));
            const estateName = inputOptions.estateList.filter(estate => estate.id === estateID)[0].name;

            axios({
                method: 'put',
                headers: {'Authorization': "Bearer " + localStorage.token},
                url: '/essence-services/essence-services/v1/360/reading',
                data: {
                    readingDate: date,
                    readingValue: passedRegisters.map(register => readingsMapByRegisterId[register.name]),
                    estateId: estateID,
                    bpEstateId: typeof bpEstateId === 'string' ? bpEstateId : bpEstateId[estateName], // object with estate name (DY) as key estateId value for multiple customers
                    readingType: "COU_C",
                    productId:  passedRegisters.map(register => register.productId),
                    meterId: meterID,
                    customerReadType: 'C',
                    process: "8",
                    channel: READING_SUBMIT_CHANNEL_360
                }
            })
            .then(data => {
                if (typeof toggle == 'function') toggle();
                ReactDOM.render(<ExpiringAlert color="success"
                                            message={i18n.t('readings.readingCreated')} />,
                                            document.getElementById('alert').appendChild(document.createElement("div")));
            })
            .catch(error => {
                if (typeof toggle == 'function') toggle();
                let errorMessage = '';
                if (error.message === 'Request failed with status code 404') errorMessage = i18n.t('default.failedWithStatus');
                else errorMessage = error.message;
                
                ReactDOM.render(<ExpiringAlert color="danger"
                                            message={errorMessage} />, document.getElementById('alert').appendChild(document.createElement("div")));
                console.log('there was an error sending the query', error.message);
            })
        } else setSubmittingReading(false);
    }

    let isSubmitBtnDisabled = 
		submittingReading ||
		(lastReading && lastReading.date.format("DD/MM/YYYY") === moment().format("DD/MM/YYYY")) ||
		(selectedMeter && !!selectedMeter.isHH);

    return (
        <div className="reading-modal">
            <Form>
                <FormGroup>
                    <label>{i18n.t('readings.address')}:&nbsp;</label>
                    <Input type="select" value={estateID} 
                        onChange={e => { 
                            setEstateID(e.target.value);
                        }}
                    >
                        {estateList && (
                            <React.Fragment>
                                {estateList.length !== 1 && <option value="">---</option>}

                                {estateList.map(estate => {
                                    const address = JSON.parse(estate.address);
                                    const addressData = [
                                        address.door, address.street, address.countryCode, address.houseNumberAdd, address.streetType, address.dependentStreet,
                                        address.doubleDependentCity, address.dependentCity, address.city, address.region, address.postalCode
                                    ];

                                    return <option value={estate.id} key={estate.id}>{addressData.filter(value => value).join(', ')}</option>
                                })}
                            </React.Fragment>
                        )}
                    </Input>
                </FormGroup>

                <FormGroup>
                    <label>{i18n.t('readings.meterPoint')}:&nbsp;</label>
                    <Input type="select" 
                        value={meterPointID} 
                        onChange={e => setMeterPointID(e.target.value)} 
                    >
                        {inputOptions.meterPointsList && getOptions(inputOptions.meterPointsList, 'id', 'identifier')}
                    </Input>
                </FormGroup>

                <FormGroup>
                    <label>{i18n.t('readings.meter')}:&nbsp;</label>
                    <Input type="select" 
                        value={meterID} 
                        onChange={e => setMeterID(e.target.value)}
                    >
                        {inputOptions.meterList && getOptions(Array.from(new Set(inputOptions.meterList.map(JSON.stringify))).map(JSON.parse), 'id', 'serialNumber')}
                    </Input>

                    {selectedMeter && !!selectedMeter.isHH && (
                        <FormText color="danger">
                            Please note that the selected meter is HH, readings cannot
                            be submitted.
                        </FormText>
                    )}
                </FormGroup>
                
                {renderMaskInput()}

                <FormGroup>
                    <label>{i18n.t('readings.date')}:&nbsp;</label>
                    <SingleDatePicker
                        openDirection={"up"}
                        date={isOutsideRange(date) ? null : date}
                        numberOfMonths={1}
                        onDateChange={date => setDate(date)}
                        isOutsideRange={isOutsideRange}
                        readOnly
                        displayFormat={"DD/MM/YYYY"}
                        focused={focus}
                        onFocusChange={({focused}) => setFocus(focused)}
                        id="create-reading-date"
                    />
                </FormGroup>

                {(lastReading && lastReading.date.format("DD/MM/YYYY") === moment().format("DD/MM/YYYY"))
                    ? <Alert color="danger">{i18n.t('readings.readingPerDay')}</Alert>
                    : ''
                }

                <Button onClick={() => handleSubmit()} 
                    disabled={isSubmitBtnDisabled}
                    color="primary"
                >
                    Submit
                </Button>
                <Button onClick={toggle} color="secondary">
                    {props.cancelButton ? props.cancelButton : 'Cancel'}
                </Button>
            </Form>
        </div>
    )

}

let withRouter2 = withRouter(CreateReading);
export default compose(
    graphql(createReadingMutation, { name: 'createReadingMutation' })
)(withRouter2);